// Here you can add other styles
.readonly-input {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.input-disabled {
  background-color: #f8d7da;
  color: #721c24;
  cursor: not-allowed;
}

.labelled-field {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

@include mqmax(lg) {
  .filter-box .input-group {
    flex: 1;
    width: 100%;
  }

  .filter-box .input-group input {
    width: 100%;
  }
}

.divider-after::after {
  content: ' ';
  width: 100%;
  margin: 1rem 0 0.3rem 0;
  display: block;
  border-top: 1px solid var(--cui-gray-200);
  opacity: 0.6;
}

.divider-after.da0p::after {
  margin: 0;
}

.card-header h1 {
  font-size: 1rem;
}

.form-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: calc(var(--cui-gutter-x, 1.5rem) * 0.5);
  padding-right: calc(var(--cui-gutter-x, 1.5rem) * 0.5);
}

.form-buttons button {
  width: auto;
}

.table-hover th {
  padding-bottom: 1rem;
}

.table-hover tr > * {
  transition: all 0.15s ease-in-out;
  vertical-align: middle;
}

.dropdown-danger {
  box-shadow: 0 0 0 1px rgb(229, 83, 83);
}

.flexr {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  gap: 1rem;
}

.recharge-totals > .values {
  overflow: hidden;
  border-radius: 0.375rem;
}

.recharge-totals > .values > div {
  padding: 0.2rem 1rem;
  transition: all 0.2s ease-in-out;
}

.recharge-totals > .values > div:nth-child(odd):not(.submit) {
  background-color: rgba(0, 0, 0, 0.025);
}

.recharge-totals > .values > div:hover:not(.submit),
.recharge-table tr:hover {
  background-color: rgba(60, 75, 100, 0.2);
}

.recharge-table tr td {
  background: transparent;
  vertical-align: middle;
}

.recharge-table tr {
  transition: all 0.2s ease-in-out;
}

.recharge-table {
  border-collapse: collapse;
  border-spacing: 0;
}

.recharge-totals > .submit {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

[role='alert'] > span.block {
  display: block;
}

.recharge-totals {
  position: relative;
}

.recharge-body {
  margin-bottom: 2rem;
}

.recharge-loading-totals {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(1px);
}
