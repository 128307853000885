$custom-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

@mixin mqmax($size) {
  @if map_has_key($custom-breakpoints, $size) {
    @media (max-width: map_get($custom-breakpoints, $size)) {
      @content;
    }
  }
}
