// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

@import "mqmax";

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";


/* A classe .no-spin remove os botões de incremento e decremento de campos de entrada do tipo número, 
garantindo uma aparência mais limpa. */
.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.no-spin {
    -moz-appearance: textfield;
}
   